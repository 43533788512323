<template>
	<div>
		<ZyroButton
			v-for="({ title, textElementData, textElementData: { font: { primary, secondary } } }, index) in typographyStylesList"
			:key="`${index}${title}`"
			data-qa="builder-sidemenu-globalstyles-btn-font"
			class="style-container style-container--hovered"
			:class="{ 'style-container--selected': isTypographyStyleSelected(title), }"
			@click="handleFontClick(title, textElementData, primary, secondary)"
		>
			<div class="style-container__content">
				<Edited
					:is-active="isWebsiteTypographyStylesEdited && isTypographyStyleSelected(title)"
				/>
				<div
					class="font z-h4 z-font-weight-normal"
					:style="{ fontFamily: primary }"
				>
					{{ extractFontName(primary) }}
					<p
						class="font--secondary z-body-small"
						:style="{ fontFamily: secondary }"
					>
						{{ $t('builder.userStyles.typography.paragraphText') }}
						- {{ extractFontName(secondary) }}
					</p>
				</div>
				<EditStyleSeparator
					:is-active="isTypographyStyleSelected(title)"
					:title="$t('builder.userStyles.typography.customizeTypography')"
				/>
			</div>
		</ZyroButton>
	</div>
</template>

<script>
import {
	mapState,
	mapMutations,
	mapGetters,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import EditStyleSeparator from '@/components/builder-drawers/drawers/partials/stylesDrawer/Misc/EditStyleSeparator.vue';
import Edited from '@/components/builder-drawers/drawers/partials/stylesDrawer/Misc/Edited.vue';
import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import {
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
} from '@/constants/globalStyles';
import { USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY } from '@/store/builder/constants/drawerPages';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import { USER_TYPOGRAPHY_RESET_ALL_CHANGES_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsFonts,
	UPDATE_FONT_STYLES,
} from '@/store/builder/fonts';
import {
	mapActionsGui,
	CHANGE_DRAWER_PAGE,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { extractFontName } from '@/utils/font';

export default {
	components: {
		Edited,
		EditStyleSeparator,
	},
	setup() {
		const {
			typographyStylesList,
			updateTypographyStylesLibrary,
			updateTypographyStyleListWithWebsiteStyles,
			isWebsiteTypographyStylesEdited,
			typographyStylesId,
		} = useTypographyStylesLibrary();

		return {
			typographyStylesList,
			updateTypographyStylesLibrary,
			updateTypographyStyleListWithWebsiteStyles,
			isWebsiteTypographyStylesEdited,
			typographyStylesId,
			extractFontName,
			PROPERTY_FONT_PRIMARY,
			PROPERTY_FONT_SECONDARY,
		};
	},
	computed: {
		...mapState(['website']),
		...mapGetters('fonts', ['getFontNames']),
	},
	mounted() {
		this.updateTypographyStyleListWithWebsiteStyles();
	},
	methods: {
		...mapMutations(['setStyleProperties']),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			changeDrawerPage: CHANGE_DRAWER_PAGE,
		}),
		...mapActionsFonts({ updateFontStyles: UPDATE_FONT_STYLES }),
		setFontSet(title, textElementData, primaryFont, secondaryFont) {
			if (this.isWebsiteTypographyStylesEdited) {
				this.openModal({
					name: USER_TYPOGRAPHY_RESET_ALL_CHANGES_MODAL,
					settings: {
						title,
						textElementData,
						primaryFont,
						secondaryFont,
					},
				});
			} else {
				const typographyStyleId = this.constructTypographyStyleId(primaryFont, secondaryFont);

				this.updateTypographyStylesLibrary(title, textElementData, primaryFont, secondaryFont);

				EventLogApi.logEvent({
					eventName: 'builder.styles.replace_typography',
					eventProperties: { typographyStyleId },
				});
			}
		},
		handleFontClick(title, textElementData, primary, secondary) {
			return this.isTypographyStyleSelected(title)
				? this.goToTypographyCategorySelect()
				: this.setFontSet(title, textElementData, primary, secondary);
		},
		constructTypographyStyleId(primaryFont, secondaryFont) {
			return `${this.extractFontName(primaryFont)}, ${this.extractFontName(secondaryFont)}`;
		},
		isTypographyStyleSelected(id) {
			return this.typographyStylesId === id;
		},
		goToTypographyCategorySelect() {
			this.changeDrawerPage({
				drawerKey: USER_STYLES_DRAWER,
				pageKey: USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY,
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';

.title {
	margin: 16px 0;
}

.font {
	text-align: left;
}
</style>
